import get from 'lodash/get';

export const supportedIdFields = ['_id', 'id', '$marketing.slug', '$marketing.rentivoSlug'];
export const prioritizedIdFields = supportedIdFields.reverse();

export const isValidProperty = (pData) => {
  if (pData === null) return false;
  return pData.id;
};

export const doesPropertyIdentifierMatch = (identifier, pData) => {
  for (let i = 0; i < supportedIdFields.length; i++) {
    let field = supportedIdFields[i];

    if (identifier === get(pData, field, false)) {
      return true;
    }
  }

  return false;
};

export const getPropertyIdentifier = (pData) => {
  for (let i = 0; i < prioritizedIdFields.length; i++) {
    let field = prioritizedIdFields[i];
    const slug = get(pData, field, "");
    if(slug !== "") {
      return slug;
    }
  }

  return null;
};
