import React, { useContext } from 'react';
import { createSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { makeSelectFullLocale } from '@rentivo/gatsby-core/src/selectors/siteConfig';

export const PropertyContext = React.createContext({});

export const withProperty = (selector = null) => WrappedComponent => {
  const WithPropertyComponent = (props) => {
    const { locale, ...rest } = props;
    const property = useContext(PropertyContext);

    const selectorProperties = (selector) ? selector({...property, locale}, props) : {};
    if(!selector) rest.property = property; // Add whole property if nothing is specified.

    return <WrappedComponent {...rest} {...selectorProperties}/>;
  };

  const mapStateToProps = () => createSelector(
    makeSelectFullLocale(),
    (locale) => ({
      locale
    })
  );

  const enhance = compose(
    connect(mapStateToProps),
  );

  return enhance(WithPropertyComponent);
};

export const PropertyProvider = ({children, ...rest}) => {
  const { property } = rest;
  return (
    <PropertyContext.Provider value={property}>
      {children}
    </PropertyContext.Provider>
  )
};


export const PropertyComponent = ({component: Component, ...rest}) => {
  const { property } = rest;
  return (
    <PropertyContext.Provider value={property}>
      <Component {...rest} property={property}/>
    </PropertyContext.Provider>
  )
};
