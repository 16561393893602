import { has, forEach, isNull, get, isString, isArray } from 'lodash';

const DEFAULT_LOCALE = 'en_GB';

export const getObjectByLocale = (localeObject, locale = null, returnIfNoDefault = null) => {
  if (!isNull(locale) && has(localeObject, locale)) return get(localeObject, locale);

  if (has(localeObject, DEFAULT_LOCALE)) return get(localeObject, DEFAULT_LOCALE);

  return returnIfNoDefault;
};

export const getContentByLocale = (localeObject, locale = null, defaultReturn = null) => {
  if (isString(localeObject)) return localeObject;
  if (isNull(localeObject)) return defaultReturn;

  const singleLocaleObject = getObjectByLocale(localeObject, locale, defaultReturn);

  if (has(singleLocaleObject, 'content')) {
    return get(singleLocaleObject, 'content');
  } else {
    return singleLocaleObject;
  }
};
