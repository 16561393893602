import t from 'typy';
import get from 'lodash/get';
import { indexOf, trim } from 'lodash';
import {
  GENERAL_PET_FRIENDLY,
  LISTING_TYPE_UNSPECIFIED,
  PRIMARY_DESCRIPTION,
  SUITABILITY_PETS_ALLOWED
} from '@rentivo/gatsby-core/src/constants/lycanConstants';

export const getItem = (obj, path, defaultValue = undefined) => {
  const item = t(obj, path);
  return item.isDefined ? item : defaultValue;
};

export const getString = (obj, path, defaultValue = undefined) => {
  const item = t(obj, path);
  return item.isDefined ? item.safeString : defaultValue;
};

export const getNumber = (obj, path, defaultValue = undefined) => {
  const item = t(obj, path);
  return item.isDefined ? item.safeNumber : defaultValue;
};

export const getNumberFromString = (obj, path, defaultValue = undefined) => {
  const item = t(obj, path);
  if(item.isNumber && item.isDefined) {
    return item.safeNumber;
  } else if(item.isString && item.isDefined) {
    return Number(item.safeString);
  } else {
    return defaultValue;
  }
};

export const getBool = (obj, path, defaultValue = undefined) => {
  const item = t(obj, path);
  return item.isDefined ? item.safeBoolean : defaultValue;
};

export const getObj = (obj, path, defaultValue = undefined) => {
  const item = t(obj, path);
  return item.isDefined ? item.safeObject : defaultValue;
};

export const getObjOrString = (obj, path, defaultValue = undefined) => {
  const item = t(obj, path);
  if(item.isObject && item.isDefined) {
    return item.safeObject;
  } else if(item.isString && item.isDefined) {
    return item.safeString;
  } else {
    return defaultValue;
  }
};

export const getArray = (obj, path, defaultValue = []) => {
  const item = t(obj, path);
  return item.isDefined ? item.input : defaultValue;
};

export const getArrayOrString = (obj, path, defaultValue = undefined) => {
  const item = t(obj, path);
  if(item.isString && item.isDefined) {
    return item.safeString;
  } else if(item.isDefined) {
    return item.input;
  } else {
    return defaultValue;
  }
};

export const getClassName = value =>
  t(value).isString ? value.toLowerCase().replace(/_/g, '-') : value;

export const getBestMatchLocale = (currentLocale, property) => {
  if (!property) {
    return null;
  }

  let supportedLocales = property['supportedLocales'] ? property['supportedLocales'] : [];

  if (indexOf(supportedLocales, currentLocale) !== -1) {
    return currentLocale; // Exists.. we can use it
  }

  // Need to see if it has multiple parts, if so split it and compare like en, en_GB etc..

  let cParts = currentLocale.split('_');

  // Loop through and determine whether there is any link in the locales

  supportedLocales.forEach((sLocale) => {
    let sSplit = sLocale.split('_');

    // We compare the start of each locale as split on en or en_GB the split[0] will be en regardless
    if (cParts[0] === sSplit[0]) {
      return sLocale; // Return this supported locale
    }
  });

  // Didn't find one - return the default $locale
  return property['$locale'] ? property['$locale'] : null;
};

export const getPropertyHeadlineByLocale = (property, locale = null) => {
  if (locale !== null && t(property, 'headline').isDefined) {
    let pLocaleHeadline = t(property, 'headline.'+locale);

    if (pLocaleHeadline.isDefined && pLocaleHeadline.isString) {
      return pLocaleHeadline.safeString;
    }
  }

  return property['name'] ? property['name'] : 'Unknown Property';
};

export const getPropertyPrimaryDescriptionByLocale = (property, locale = null) => {
  let description = '';
  if(!property || !locale) return description;
  const texts = get(property, 'texts', []);
  if(texts.length === 0) return description;
  const translatableDescriptions = getTranslatableObjectByType(texts, PRIMARY_DESCRIPTION);
  if (translatableDescriptions === null || translatableDescriptions.length === 0) return null;
  const localisedDescriptionObject = getClosestContentObjByLocale(translatableDescriptions, locale);
  description = localisedDescriptionObject.content;
  if (!description) return null;
  return description;
};

export const getTranslatableObjectByType = (items, type) => {
  const found = items.filter(item => {
    return item.type === type;
  });

  return found.length === 1 ? found[0].description : null;
};

// This gets content by locale.
// If the content property is a string, it just uses this...
// If the content property is an object, it selects the language by the provided locale
// If the content property is an object,
//       but the locale provide either doesn't exist or doesn't match an existing locale key in the content,
//       then default to the first language in the content object
// Otherwise, return blank
export const getContentByLocale = (localeObj, locale = null) => {
  let returnContent = '';

  const localeObjOrString = t(localeObj);

  if (localeObjOrString.isObject && locale) {

    const firstKeyInObj = Object.keys(localeObj)[0];
    const contentInLocale = t(localeObj, `${locale}`);
    const contentInLocaleByFirstKey = t(localeObj, `${firstKeyInObj}`);

    if (contentInLocale.isString && contentInLocale.safeString) {
      returnContent =  contentInLocale.safeString;
    } else if(contentInLocaleByFirstKey.isString && contentInLocaleByFirstKey.safeString) {
      returnContent =  contentInLocaleByFirstKey.safeString;
    } else {
      returnContent =  '';
    }

  } else if (localeObjOrString.isObject) {

    const firstKeyInObj = Object.keys(localeObj)[0];
    const contentInLocaleByFirstKey = t(localeObj, `${firstKeyInObj}`);

    if(contentInLocaleByFirstKey.isString && contentInLocaleByFirstKey.safeString) {
      returnContent =  contentInLocaleByFirstKey.safeString;
    }

  } else if (localeObjOrString.isString && localeObjOrString.safeString) {
    returnContent =  localeObjOrString.safeString;
  }

  return returnContent;
};

export const getClosestContentObjByLocale = (contentArr, locale) => {
  if(contentArr && contentArr.length > 0) {
    const firstItem = contentArr[0];
    const findCorrectLocale = contentArr.filter(cItem => cItem.locale === locale);
    if(findCorrectLocale && findCorrectLocale.length > 0) {
      return findCorrectLocale[0];
    } else {
      return firstItem;
    }
  } else {
    return null;
  }
};

export const getClosestContentByLocale = (contentArr, locale) => {
  if(contentArr && contentArr.length > 0) {
    const firstItem = contentArr[0];
    const findCorrectLocale = contentArr.filter(cItem => cItem.locale === locale);
    if(findCorrectLocale && findCorrectLocale.length > 0) {
      return findCorrectLocale[0].content;
    } else {
      return firstItem.content;
    }
  } else {
    return null;
  }
};

export const getMenuByLocation = (menusArray, menuLocation) => {
  const menu = menusArray.filter(
    m =>
      m.placement === menuLocation && m.active && m.items && m.items.length > 0,
  );
  return menu.length > 0 ? menu[0] : false;
};

// Recursive function to pluck objs by key in multi-dimensional objects and arrays
export const getObjectsByKeyInComplexValue = (thing, needle, loopNumber = 0, itemsFound = []) => {
  loopNumber++;
  if(Array.isArray(thing)) {
    thing.map((t, i) => {
      getObjectsByKeyInComplexValue(t, needle, loopNumber, itemsFound);
    });
  } else if (typeof thing === 'object' ) {
    for (let property in thing) {
      if (thing.hasOwnProperty(property)) {
        if(property === needle) {
          itemsFound.push(thing[property]);
        } else {
          getObjectsByKeyInComplexValue(thing[property], needle, loopNumber, itemsFound);
        }
      }
    }
    return itemsFound;
  } else {
    return itemsFound;
  }
};

export const getCDNImageUrl = (imageURL, transformation, cdnEndpoint) => {
  if(cdnEndpoint) {
    let finalCDNEndpoint = cdnEndpoint;
    if(cdnEndpoint.substr(-1) === '/') {
      finalCDNEndpoint = cdnEndpoint.substr(0, cdnEndpoint.length - 1);
    }
    return finalCDNEndpoint + "/" + transformation + "/" + imageURL;
  } else {
    return imageURL
  }
};

export const getCDNSrcSet = (imageURL, transformation, siteConfig) => {
  const cdnEndpoint = get(siteConfig, 'general.images.cdnEndpoint', false);
  const theTransformation = get(siteConfig, `general.images.transformations.${transformation}`, false);

  let theSrcSet = {
    uri: null,
    srcSet: null,
    sizes: null,
    placeholder: null
  };

  if(cdnEndpoint && theTransformation) {

    const mobile = getCDNImageUrl(imageURL, theTransformation.mobile.tr, cdnEndpoint);
    const tablet = getCDNImageUrl(imageURL, theTransformation.tablet.tr, cdnEndpoint);
    const desktop = getCDNImageUrl(imageURL, theTransformation.desktop.tr, cdnEndpoint);
    const large = getCDNImageUrl(imageURL, theTransformation.large.tr, cdnEndpoint);
    const thumbnail = getCDNImageUrl(imageURL, theTransformation.thumbnail.tr, cdnEndpoint);


    const srcSet = `
        ${mobile} ${theTransformation.mobile.width}w,
        ${tablet} ${theTransformation.tablet.width}w,
        ${desktop} ${theTransformation.desktop.width}w,
        ${large} ${theTransformation.large.width}w
      `;

    const sizes = `
      (max-width: 768px)  ${theTransformation.mobile.width}px,
      (max-width: 992px)  ${theTransformation.tablet.width}px,
      (max-width: 1170px) ${theTransformation.desktop.width}px,
      (max-width: 1920px) ${theTransformation.large.width}px,
      ${theTransformation.large.width}px
    `;

    const placeholderTr = get(theTransformation, 'placeholder.tr', false);
    const placeholder = (placeholderTr) ? getCDNImageUrl(imageURL, theTransformation.placeholder.tr, cdnEndpoint) : '';

    theSrcSet = {
      uri: {
        mobile,
        tablet,
        desktop,
        large,
        thumbnail
      },
      srcSet,
      sizes,
      placeholder
    }

  }

  return theSrcSet;
};

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  const l = s.toLowerCase();
  return l.charAt(0).toUpperCase() + l.slice(1);
};

export const getFriendlyFeatureName = (FEATURE_IN_CAPS, onlyEndItem = false, textTransform = 'capitalize') => {
  if(FEATURE_IN_CAPS === LISTING_TYPE_UNSPECIFIED) return null;
  let friendly = '';
  if(onlyEndItem) {
    const featureItems = FEATURE_IN_CAPS.split('_');
    friendly = (featureItems[featureItems.length - 1] !== undefined) ? featureItems[featureItems.length - 1].toLowerCase() : '';
  } else {
    friendly = FEATURE_IN_CAPS.replace(/_/g, ' ').toLowerCase();
  }

  if(textTransform === 'capitalize') {
    friendly = capitalize(friendly);
  }

  return trim(friendly);
};

export const hasErrors = (fieldsError) => {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
};


export const getIsPetFriendly = (features = []) => {
  if(!features || features.length === 0) return false;
  let isPetFriendly = false;
  features.forEach(f => {
    if(f.type && (f.type === SUITABILITY_PETS_ALLOWED || GENERAL_PET_FRIENDLY)) isPetFriendly = true;
  });
  return isPetFriendly;
};
