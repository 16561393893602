import { get, uniqBy } from 'lodash'
import {
  newlinesToBr,
  stringToFloat,
  stringToInt,
} from '@rentivo/gatsby-core/src/utils/strings'
import {
    AMENITIES,
    AVAILABILITY_CONFIG_DEFAULT,
    AVAILABILITY_INSTANT_BOOKABLE_NO,
    BEDROOM,
    BOOKING_TERMS,
    CHANGEOVER_CONFIG_DEFAULT, FLOORPLAN,
    GENERAL_PET_FRIENDLY,
    PHOTO,
    PRIMARY_DESCRIPTION,
    SUITABILITY_PETS_ALLOWED,
} from '@rentivo/gatsby-core/src/constants/lycanConstants'
import { createSelector } from 'reselect'
import { getContentByLocale } from '@rentivo/gatsby-core/src/utils/schema'
import { getPropertyIdentifier } from '@rentivo/gatsby-core/src/utils/property'
import React from 'react'
import { getClosestContentObjByLocale } from '@rentivo/gatsby-core/src/utils/getters'
import { mapRange } from '@rentivo/gatsby-core/src/utils/numbers'

// Single root level
export const selectFullLocale = property =>
  get(property, 'locale', selectPropertyLocale(property))

export const selectPropertyId = property => get(property, 'id', null)
export const makeSelectPropertyId = () =>
  createSelector(selectPropertyId, state => state)

export const selectProperty = property => property

export const selectPropertySlug = property => getPropertyIdentifier(property)

export const selectPropertyName = property => get(property, 'name', null)

export const selectPropertyHeadline = property =>
  get(property, 'headline', null)
export const makeSelectPropertyHeadline = createSelector(
  selectFullLocale,
  selectPropertyHeadline,
  selectPropertyName,
  (locale, name, headline) => getContentByLocale(headline, locale, name)
)

export const selectPropertyProviderListingId = property =>
  get(property, '$providerListingId', null)
export const selectPropertyLocale = property =>
  get(property, '$locale', 'en_GB')
export const selectPropertyIndexedAt = property =>
  get(property, '$indexedAt', null)

export const selectPropertyCancellationPolicy = property =>
  get(property, '$booking.cancellationKey', 'RELAXED')
export const selectPropertyCancellations = property =>
  get(property, 'cancellations', null)

export const selectPropertyAvailabilityPerc = property =>
  get(property, 'availabilityPerc', null)

// Address
export const selectPropertyAddress = property => get(property, 'address', null)
export const makeSelectPropertyAddress = () =>
  createSelector(selectPropertyAddress, state => state)

export const selectPropertyAddressCity = property =>
  get(selectPropertyAddress(property), 'city', null)
export const makeSelectPropertyAddressCity = () =>
  createSelector(selectPropertyAddressCity, state => state)

export const selectPropertyAddressState = property =>
  get(selectPropertyAddress(property), 'stateProvince', null)
export const makeSelectPropertyAddressState = () =>
  createSelector(selectPropertyAddressState, state => state)

export const selectPropertyAddressCountryCode = property =>
  get(selectPropertyAddress(property), 'countryISO2', null)
export const makeSelectPropertyAddressCountryCode = () =>
  createSelector(selectPropertyAddressCountryCode, state => state)

export const makeSelectPropertyLocation = createSelector(
  selectPropertyAddressCity,
  selectPropertyAddressState,
  (city, state) => {
    return state ? `${city}, ${state}` : city
  }
)

// Arrival & departure
export const selectPropertyArrival = property => get(property, 'arrival', {})
export const selectPropertyArrivalCheckInEndTime = property =>
  get(selectPropertyArrival(property), 'checkInEndTime', null)
export const selectPropertyArrivalCheckInStartTime = property =>
  get(selectPropertyArrival(property), 'checkInStartTime', null)
export const selectPropertyDeparture = property =>
  get(property, 'departure', {})
export const selectPropertyDepartureCheckOutTime = property =>
  get(selectPropertyDeparture(property), 'checkOutTime', null)

// Debug
export const selectPropertyDebug = property => get(property, 'debug', {})
export const selectPropertyDebugUnmappedFeatures = property =>
  get(selectPropertyDebug(property), 'unmapped.features', [])

// Marketing
export const selectPropertyMarketing = property =>
  get(property, '$marketing', {})
export const selectPropertyMarketingSlug = property =>
  get(selectPropertyMarketing(property), 'slug', null)
export const selectPropertyMarketingRentivoSlug = property =>
  get(selectPropertyMarketing(property), 'rentivoSlug', null)
export const selectPropertyMarketingAgent = property =>
  get(selectPropertyMarketing(property), 'agent', {})
export const selectPropertyMarketingAgentEmails = property =>
  get(selectPropertyMarketingAgent(property), 'emails', [])
export const selectPropertyMarketingAgentBrandCompanyName = property =>
  get(selectPropertyMarketingAgent(property), 'brandCompanyName', null)
export const selectPropertyMarketingAgentDescriptiveName = property =>
  get(selectPropertyMarketingAgent(property), 'descriptiveName', null)
export const selectPropertyMarketingAgentPhone = property =>
  get(selectPropertyMarketingAgent(property), 'phone', null)
export const selectPropertyMarketingAgentLogo = property =>
  get(selectPropertyMarketingAgent(property), 'logo', {})
export const selectPropertyMarketingAgentLogoPublicUrl = property =>
  get(selectPropertyMarketingAgentLogo(property), 'publicUrl', null)
export const selectPropertyMarketingAgentLogoWidth = property =>
  get(selectPropertyMarketingAgentLogo(property), 'width', null)
export const selectPropertyMarketingAgentLogoHeight = property =>
  get(selectPropertyMarketingAgentLogo(property), 'height', null)
export const selectPropertyMarketingAgentBiography = property =>
  get(selectPropertyMarketingAgent(property), 'biography', null)

// Meta
export const selectPropertyMeta = property => get(property, '$meta', {})
export const selectPropertyMetaImageCount = property =>
  get(selectPropertyMeta(property), 'imageCount', 0)
export const selectPropertyMetaAvailableNights180 = property =>
  get(selectPropertyMeta(property), 'availableNights180', 0)

// Features
export const selectPropertyFeatures = property =>
  uniqBy(get(property, 'features', []), f => f.type)
export const selectPropertyFeaturesByCategory = (
  property,
  category = AMENITIES
) => selectPropertyFeatures(property).filter(f => f.category === category)

// Rooms
export const selectPropertyRooms = property => get(property, 'rooms', [])
export const selectPropertyRoomsByTypeFromRooms = (rooms, type = BEDROOM) =>
  rooms.filter(r => r.type === type)

export const selectPropertyFeatureSetsByLocaleFromFeatures = (
  features,
  locale
) => {
  if (!features || features.length === 0) return []
  // Get all the different categories
  const categories = features.map(f => f.category)
  const uniqueCategories = [...new Set(categories)]

  // Now loop through the categories & build an array of categories with their filters
  let featureSets = []

  uniqueCategories.forEach(category => {
    const featuresForThisC = uniqBy(features, 'type')
      .filter(f => f.category === category)
      .map(f => {
        let description =
          f.description && f.description.length > 0
            ? getClosestContentObjByLocale(f.description, locale)
            : null
        description =
          description && description.content ? description.content : null

        return {
          ...f,
          description,
        }
      })
    featureSets.push({
      category,
      features: featuresForThisC,
    })
  })

  return featureSets
}

// Pet Friendly
export const selectPropertyIsPetFriendlyFromFeatures = features => {
  if (!features || features.length === 0) return false
  let isPetFriendly = false
  features.forEach(f => {
    if (
      f.type &&
      (f.type === SUITABILITY_PETS_ALLOWED || f.type === GENERAL_PET_FRIENDLY)
    )
      isPetFriendly = true
  })
  return isPetFriendly
}

export const makeSelectPropertyIsPetFriendly = createSelector(
  selectPropertyFeatures,
  features => {
    if (!features || features.length === 0) return false
    let isPetFriendly = false
    features.forEach(f => {
      if (
        f.type &&
        (f.type === SUITABILITY_PETS_ALLOWED || f.type === GENERAL_PET_FRIENDLY)
      )
        isPetFriendly = true
    })
    return isPetFriendly
  }
)

// Flags
export const selectPropertyFlags = property => property.flags ?? {}
export const selectPropertyFlagsIsActive = property =>
  get(selectPropertyFlags(property), 'isActive', true)
export const selectPropertyFlagsIsFeatured = property =>
  get(selectPropertyFlags(property), 'isFeatured', false)

// Listing
/*
export const selectPropertyListing = property => property.listing ?? {};
export const selectPropertyListingArrangement = property => property.listing?.arrangement ?? null;
export const selectPropertyListingBathrooms = property => property.listing?.bathrooms ?? null;
export const selectPropertyListingBedrooms = property => property.listing?.bedrooms ?? null;
export const selectPropertyListingBeds = property => property.listing?.beds ?? null;
export const selectPropertyListingHousekeeping = property => property.listing?.housekeeping ?? null;
export const selectPropertyListingMaxOccupancy = property => property.listing?.maxOccupancy ?? selectPropertyListingSleeps(property);
export const selectPropertyListingOverflow = (sleeps, maxOccupancy) => (parseInt(maxOccupancy) > parseInt(sleeps)) ? parseInt(maxOccupancy) - parseInt(sleeps) : 0;
export const selectPropertyListingMeals = property => property.listing?.meals ?? null;
export const selectPropertyListingSleeps = property => property.listing?.sleeps ?? null;
export const selectPropertyListingType = property => property.listing?.type ?? null;
export const selectPropertyListingMaxPets = property => property.listing?.maxPets ?? 0;
*/

// Tags
export const selectPropertyTags = property => get(property, 'tags', [])
/*
[
  {
    "tag": "websitetags",
    "value": "Best Rated"
  },
  {
    "tag": "websitetags",
    "value": "Deals"
  },
  {
    "tag": "source",
    "value": "Rentals United"
  }
]
 */

// Listing
export const selectPropertyListing = property => get(property, 'listing', {})
export const selectPropertyListingArrangement = property =>
  get(selectPropertyListing(property), 'arrangement', null)
export const selectPropertyListingBathrooms = property =>
  get(selectPropertyListing(property), 'bathrooms', null)
export const selectPropertyListingBedrooms = property =>
  get(selectPropertyListing(property), 'bedrooms', null)
export const selectPropertyListingBeds = property =>
  get(selectPropertyListing(property), 'beds', null)
export const selectPropertyListingHousekeeping = property =>
  get(selectPropertyListing(property), 'housekeeping', null)
export const selectPropertyListingMaxOccupancy = property =>
  get(
    selectPropertyListing(property),
    'maxOccupancy',
    selectPropertyListingSleeps(property)
  )
export const selectPropertyListingOverflow = (sleeps, maxOccupancy) =>
  parseInt(maxOccupancy) > parseInt(sleeps)
    ? parseInt(maxOccupancy) - parseInt(sleeps)
    : 0
export const selectPropertyListingMeals = property =>
  get(selectPropertyListing(property), 'meals', null)
export const selectPropertyListingSleeps = property =>
  get(selectPropertyListing(property), 'sleeps', null)
export const selectPropertyListingType = property =>
  get(selectPropertyListing(property), 'type', null)
export const selectPropertyListingMaxPets = property =>
  get(selectPropertyListing(property), 'maxPets', 0)

export const makeSelectPropertyListingPetsObject = createSelector(
  makeSelectPropertyIsPetFriendly,
  selectPropertyListingMaxPets,
  (isPetFriendly, maxPets) => {
    const isActuallyPetFriendly = maxPets > 0 || isPetFriendly
    const actualMaxPets = isActuallyPetFriendly && maxPets === 0 ? 1 : maxPets
    return {
      maxPets: actualMaxPets,
      isPetFriendly: isActuallyPetFriendly,
    }
  }
)

// Location
export const selectPropertyLocation = property => get(property, 'location', {})
export const selectPropertyLocationLat = property =>
  stringToFloat(get(selectPropertyLocation(property), 'lat', null))
export const selectPropertyLocationLng = property =>
  stringToFloat(get(selectPropertyLocation(property), 'lon', null))
export const selectPropertyLocationDisplayExactLocation = property =>
  get(selectPropertyLocation(property), 'displayExactLocation', true)

// Location raw
export const selectPropertyLocationRaw = property =>
  get(property, 'locationRaw', {})
export const selectPropertyLocationRawLat = property =>
  stringToFloat(get(selectPropertyLocationRaw(property), 'latitude', null))
export const selectPropertyLocationRawLng = property =>
  stringToFloat(get(selectPropertyLocationRaw(property), 'longitude', null))

// Media
export const selectPropertyMedia = property => get(property, 'media', [])
export const selectPropertyMediaCleanedFromMedia = media =>
  uniqBy(media, m => m.uri)

export const selectPropertyMediaByCategoryFromMedia = (media, categories = [PHOTO, FLOORPLAN]) => {
    if (typeof categories == "string") {
        categories = [categories];
    }

    return selectPropertyMediaCleanedFromMedia(media).filter(m => categories.includes(m.category))
}

export const selectPropertyPhotoFeaturedImageFromMedia = media =>
  get(selectPropertyMediaByCategoryFromMedia(media, [PHOTO, FLOORPLAN]), '0', null)
export const selectPropertyPhotosFromToFromMedia = (media, from = 0, to = 10) =>
  selectPropertyMediaByCategoryFromMedia(media, [PHOTO, FLOORPLAN]).filter(
    (m, i) => i >= from && i < to
  )

// Texts
export const selectPropertyPolicies = property => get(property, 'policies', [])

export const selectPropertyPoliciesByTypes = (
  allPolicies,
  locale,
  types = [BOOKING_TERMS]
) => {
  return allPolicies
    .filter(pi => types.includes(pi.type))
    .map(policy => {
      const descriptionsByLocale = policy.description.filter(
        d => d.locale === locale
      )
      const { title, content } = descriptionsByLocale.length
        ? descriptionsByLocale[0]
        : policy.description.length
        ? policy.description[0]
        : null
      return {
        type: policy.type,
        title,
        content: content ? newlinesToBr(content) : null,
      }
    })
}

export const selectPropertyTexts = property => get(property, 'texts', [])
export const selectPropertyTextsByTypeFromTexts = (
  texts,
  type = PRIMARY_DESCRIPTION
) => texts.filter(m => m.type === type)
export const selectPropertyTextByTypeFromTexts = (
  texts,
  type = PRIMARY_DESCRIPTION
) => get(selectPropertyTextsByTypeFromTexts(texts, type), '0.description', [])
export const selectPropertyTextByTypeByLocaleFromTexts = (
  texts,
  type = PRIMARY_DESCRIPTION,
  locale = 'en'
) => {
  return newlinesToBr(
    get(
      selectPropertyTextByTypeFromTexts(texts, type).filter(m =>
        m.locale.includes(locale)
      ),
      '0.content',
      null
    )
  )
}

// Pricing
export const selectPropertyPricing = property => get(property, 'pricing', {})
export const makeSelectPropertyPricing = () =>
  createSelector(selectPropertyPricing, state => state)

export const selectPropertyPricingLos = property =>
  get(selectPropertyPricing(property), 'los', [])
export const makeSelectPropertyPricingLos = () =>
  createSelector(selectPropertyPricingLos, state => state)

export const selectPropertyPricingVisual = property =>
  get(selectPropertyPricing(property), 'visual', {})
export const makeSelectPropertyPricingVisual = () =>
  createSelector(selectPropertyPricingVisual, state => state)

export const selectPropertyPricingVisualCurrency = property =>
  get(selectPropertyPricingVisual(property), 'currency', 'USD')
export const makeSelectPropertyPricingVisualCurrency = () =>
  createSelector(selectPropertyPricingVisualCurrency, state => state)

export const selectPropertyPricingVisualWeeklyLow = property =>
  get(selectPropertyPricingVisual(property), 'weeklyLow', null)
export const makeSelectPropertyPricingVisualWeeklyLow = () =>
  createSelector(selectPropertyPricingVisualWeeklyLow, state => state)

export const selectPropertyPricingVisualWeeklyHigh = property =>
  get(selectPropertyPricingVisual(property), 'weeklyHigh', null)
export const makeSelectPropertyPricingVisualWeeklyHigh = () =>
  createSelector(selectPropertyPricingVisualWeeklyHigh, state => state)

export const selectPropertyPricingVisualNightlyLow = property =>
  get(selectPropertyPricingVisual(property), 'nightlyLow', null)
export const makeSelectPropertyPricingVisualNightlyLow = () =>
  createSelector(selectPropertyPricingVisualNightlyLow, state => state)

export const selectPropertyPricingVisualNightlyHigh = property =>
  get(selectPropertyPricingVisual(property), 'nightlyHigh', null)
export const makeSelectPropertyPricingVisualNightlyHigh = () =>
  createSelector(selectPropertyPricingVisualNightlyHigh, state => state)

export const selectPropertyPricingVisualDiscounts = property =>
  get(selectPropertyPricingVisual(property), 'discounts', [])
export const makeSelectPropertyPricingVisualDiscounts = () =>
  createSelector(selectPropertyPricingVisualDiscounts, state => state)

export const selectPropertyPricingVisualSpecialDiscounts = property =>
  get(selectPropertyPricingVisual(property), 'specialDiscounts', [])

export const selectPropertyPricingDynamic = property =>
  get(selectPropertyPricing(property), 'dynamic', {})
export const selectPropertyPricingDynamicData = property =>
  get(selectPropertyPricingDynamic(property), 'data', [])
export const selectPropertyPricingDynamicPeriods = property =>
  get(selectPropertyPricingDynamicData(property), '0.periods', [])

// Fields
export const selectPropertyFields = property => get(property, 'fields', {})
export const selectPropertyFieldsLosPrice = property =>
  get(selectPropertyFields(property), 'lengthOfStayPrice.0', null)
export const selectPropertyFieldsLosCurrency = property =>
  get(selectPropertyFields(property), 'lengthOfStayPrice.1', null)
export const selectPropertyFieldsDiscounts = property =>
  get(selectPropertyFields(property), 'discounts', [])

// Availability
export const selectPropertyUnitAvailability = property =>
  get(property, 'unitAvailability', {})
export const selectPropertyUnitAvailabilityChangeoverDefault = property =>
  stringToInt(
    get(selectPropertyUnitAvailability(property), 'changeoverDefault', '1')
  )
export const selectPropertyUnitAvailabilityConfiguration = property =>
  get(selectPropertyUnitAvailability(property), 'configuration', {})
export const selectPropertyUnitAvailabilityConfigurationAvailability = property =>
  get(
    selectPropertyUnitAvailabilityConfiguration(property),
    'availability',
    AVAILABILITY_CONFIG_DEFAULT
  )
export const selectPropertyUnitAvailabilityConfigurationChangeover = property =>
  get(
    selectPropertyUnitAvailabilityConfiguration(property),
    'changeover',
    CHANGEOVER_CONFIG_DEFAULT
  )
export const selectPropertyUnitAvailabilityDateRange = property =>
  get(selectPropertyUnitAvailability(property), 'dateRange', {})
export const selectPropertyUnitAvailabilityDateRangeStartDate = property =>
  get(selectPropertyUnitAvailabilityDateRange(property), 'startDate', null)
export const selectPropertyUnitAvailabilityDateRangeEndDate = property =>
  get(selectPropertyUnitAvailabilityDateRange(property), 'endDate', null)
export const selectPropertyUnitAvailabilityInstantBookableDefault = property =>
  get(
    selectPropertyUnitAvailability(property),
    'instantBookableDefault',
    AVAILABILITY_INSTANT_BOOKABLE_NO
  )
export const selectPropertyUnitAvailabilityMinPriorBookableDefault = property =>
  get(selectPropertyUnitAvailability(property), 'minPriorBookableDefault', 0)
export const selectPropertyUnitAvailabilityMinStayDefault = property =>
  get(selectPropertyUnitAvailability(property), 'minStayDefault', 0)

// Reviews
export const selectPropertyReviews = property => get(property, 'reviews', [])
export const selectPropertyAverageRatingFromReviews = (
  reviews,
  ratingOutOf = 5
) => {
  let averageRating = 0
  let counter = 0
  let score = 0

  reviews.forEach(r => {
    if (r.rating && r.rating.score && r.rating.max) {
      counter++
      score = score + mapRange(r.rating.score, 0, r.rating.max, 0, ratingOutOf)
    }
  })

  if (reviews.length > 0 && counter > 0 && score > 0)
    averageRating = (score / counter).toFixed(1)

  return averageRating
}
